:root {
    --orange-99: hsl(16 100% 99%);
    --orange-98: hsl(16 100% 98%);
    --orange-97: hsl(16 100% 97%);
    --orange-50: hsl(16 6.25% 50%);
    --orange-5: hsl(16 15.625% 43.75%);
    --orange-2: hsl(16 100% 2%);
    --orange-1: hsl(16 100% 1%);

    --blue:  hsl(196 100% 17%);
    --blue-3:  hsl(196 100% 3%);
    --blue-2:  hsl(196 100% 2%);
    --blue-1:  hsl(196 100% 1%);

    --pink: hsl(334 78% 62%);
    --pink-99: hsl(334 78% 99%);
    --pink-98: hsl(334 78% 98%);
    --pink-50: hsl(334 50% 62%);
    --pink-2: hsl(334 78% 2%);
    --pink-1: hsl(334 78% 1%);

    --green-99: hsl(154 78% 99%);

    --text-color: var(--blue-1);
    --background-color: var(--orange-99);
    --main-color: var(--blue);
    --accent-color: var(--pink);
    --accent-foreground-color: var(--green-99);

    --size-base: 1rem;
    --size-1: 1.2rem;
    --size-2: 1.44rem;
    --size-3: 1.728rem;
    --size-4: 2.074rem;
    --size-5: 2.488rem;
    --size-6: 2.986rem;
}

@font-face {
  font-family: 'PP Mori';
  font-style: normal;
  font-weight: 400;
  src: url("../font/PPMori-Regular.woff2") format('woff2');
}

@font-face {
  font-family: 'PP Mori';
  font-style: italic;
  font-weight: 400;
  src: url("../font/PPMori-RegularItalic.woff2") format('woff2');
}
@font-face {
  font-family: 'PP Mori';
  font-style: normal;
  font-weight: 600;
  src: url("../font/PPMori-SemiBold.woff2") format('woff2');
}

@font-face {
  font-family: 'PP Pangaia';
  font-style: normal;
  font-weight: 500;
  src: url("../font/PPPangaia-Medium.woff2") format('woff2');
}

@font-face {
  font-family: 'PP Pangaia';
  font-style: italic;
  font-weight: 500;
  src: url("../font/PPPangaia-MediumItalic.woff2") format('woff2');
}


html {
    font-size: 14px;
    color: var(--text-color);
    background-color: var(--background-color);

    body {
	font-family: "PP Mori", sans-serif;
	font-optical-sizing: auto;
	font-weight: 400;
	font-style: normal;
	font-variation-settings: "slnt" 0;
	text-rendering: optimizeLegibility;
	font-feature-settings: "kern" on;
	line-height: 1.425;

	display: grid;
	grid-template-columns: 1fr minmax(auto, 1024px) 1fr;

	h1, h2, h3, h4, h5, h6 {
	    font-family: "PP Pangaia", serif;
	    font-weight: 500;
	    margin-top: var(--size-3);
	    margin-bottom: var(--size-1);
	    max-width: 44rem;
	}

	h1 {
	    font-size: var(--size-6);
	}

	h2 {
	    font-size: var(--size-5);
	}

	h3 {
	    font-size: var(--size-4);
	}

	h4 {
	    font-size: var(--size-3);
	}

	h5 {
	    font-size: var(--size-2);
	}

	h6 {
	    font-size: var(--size-1);
	}

	p {
	    margin-top: var(--size-base);
	    margin-bottom: var(--size-base);

	    /*min-width: 26rem;*/
	    max-width: 44rem;
	}

	a {
	    text-decoration: none;
	    color: inherit;

	    text-decoration: none;

	    font-weight: 600;
	}

	header {
	    display: grid;
	    grid-template-columns: subgrid;
	    grid-column: 1 / span 3;
	    padding: var(--size-base) var(--size-1) var(--size-2);

	    background-color: var(--blue);
	    color: var(--orange-99);

	    .container {
		grid-column: 2;

		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: baseline;
		justify-content: space-between;

		h1 {
		    flex-grow: 3;
		}

		nav {
		    flex-basis: fit-content;
		    flex-grow: 1;
		    flex-shrink: 1;
		    display: flex;
		    flex-direction: row;
		    flex-wrap: wrap;
		    justify-content: space-between;

		    a {
			flex-basis: max-content;
			border-bottom: solid var(--accent-color) 2px;
		    }
		}
	    }
	}

	section {
	    display: grid;
	    grid-template-columns: subgrid;
	    grid-column: 1 / span 3;

	    padding-top: var(--size-4);

	    .container {
		grid-column: 2;
	    }

	    .cards {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: var(--size-2);
	    }

	    &#hero {
		min-height: 35rem;
		padding: 0;
		background-color: var(--blue);
		color: var(--orange-99);

		.container {
		    display: flex;
		    flex-direction: row;
		    flex-wrap: wrap;
		    align-items: center;
		    justify-content: space-between;

		    #intro {
			p {
			    max-width: 38rem;
			}

			#connect {
			    width: min-content;
			    display: flex;
			    flex-direction: column;
			    flex-wrap: nowrap;
			    align-items: flex-start;
			    justify-content: space-between;
			    gap: 0.493rem;

			    a {
				width: 5.852rem;
				text-decoration: none;
				background-color: var(--accent-color);
				color: var(--accent-foreground-color);
				border-radius: 8px;
				padding: 0.216rem 1rem;
				text-align: center;
			    }
			}
		    }

		    img {
			display: block;
			width: 20rem;
			aspect-ratio: 1 / 1;
		    }
		}
	    }

	    &#acerca-de {
		.container {
		    display: flex;
		    flex-direction: column;
		    align-items: center;

		    .copy {
			padding-bottom: var(--size-6);

			h2 {
			    text-align: center;
			}
		    }
		}
	    }

	    &#articulos {
		padding-bottom: var(--size-6);
	    }
	}

	.card {
	    max-width: 20rem;

	    display: grid;
	    grid-template-rows: auto 1fr;

	    box-shadow: 1px 1px 6px hsl(17 100% 5% / 0.33);
	    border-radius: 12px;

	    overflow: hidden;

	    background-color: var(--orange-99);

	    .media {
		width: 100%;
		aspect-ratio: 3 / 2;

		iframe {
		    width: 100%;
		    height: 100%;
		}

		img {
		    width: 100%;
		    height: 100%;
		    object-fit: cover;
		}
	    }

	    .content {
		display: grid;
		grid-template-rows: auto 1fr;

		padding: var(--size-1);
		
		p {
		    margin-top: 0;
		}

		a {
		    margin-top: 1rem;
		    margin-bottom: 1rem;

		    justify-self: center;
		    align-self: end;

		    color: var(--orange-50);

		    font-weight: 600;
		}
	    }
	}

	footer {
	    display: grid;
	    grid-template-columns: subgrid;
	    grid-column: 1 / span 3;

	    padding: var(--size-6) 0;

	    background-color: var(--orange-97);
	    color: var(--orange-5);

	    .container {
		grid-column: 2;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: space-between;
	    }
	}
    }
}

@media (min-width: 425px) {
    html body section#hero .container #intro #connect {
	flex-direction: row;  
    }
}

@media (min-width: 614px) {
    html body section .cards {
	justify-content: flex-start;
    }
}

@media (min-width: 768px) {
    html body section .cards {
	justify-content: flex-start;
    }
}

@media (min-width: 1024px) {
    html {
	font-size: 16px;

	body section#hero .container{
	    background-position: right center;
	    background-image: url('../img/logo.png');
	    background-size: 30%;
	    background-repeat: no-repeat;

	    #intro #connect a {
		background-color: var(--accent-color);
		color: var(--white);
	    }
	}
    }
}
